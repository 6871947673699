import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { LifeCycle } from '@wix/thunderbolt-symbols'
import { TpaHandlersDistributor } from './tpaHandlersDistributor'
import { MasterPageTpaPropsCacheSymbol } from './symbols'
import { TpaPropsCacheFactory } from './tpaPropsCache'

export const site: ContainerModuleLoader = (bind) => {
	bind(LifeCycle.AppDidMountHandler).to(TpaHandlersDistributor)
	bind(MasterPageTpaPropsCacheSymbol).to(TpaPropsCacheFactory)
}

export { TpaHandlersManagerSymbol, name } from './symbols'
export { MasterPageTpaPropsCacheSymbol }
export * from './types'
